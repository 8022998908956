// Add type definition for AuthState
type AuthState = {
  isAuthenticated: boolean;
};
export default defineNuxtRouteMiddleware((to, from) => {
  const auth = useState<AuthState>('auth');

  // If not authenticated and trying to access a protected route, redirect to login
  if (!auth.value.isAuthenticated && to.path !== '/login') {
    return navigateTo('/login');
  }

  // If authenticated and trying to access login page, redirect to dashboard
  if (auth.value.isAuthenticated && to.path === '/login') {
    return navigateTo('/dashboard');
  }
});